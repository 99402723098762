.main {
  text-align: center;
  background-color: #328bff;
  min-height: 100vh;
  color: white;
}

.modal-width {
  max-width: 100%;
}

.mark:before, .mark:after {
  content: '';
  display: block;
  position: absolute;
}

.exclamation-point:before {
  top: 15%;
  left: 30%;
  height: 10px;
  width: 3px;
  border: solid #e67e22;
  border-width: 0 3px 0 0;
}
